<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/Layout.vue";
import CategoryBox from "@/Pages/Shared/CategoryBox.vue";
import VerticalProductBox from "@/Pages/Shared/VerticalProductBox.vue";
import ProductBox from "@/Pages/Shared/ProductBox.vue";
import VerticalProductList from "@/Pages/Shared/VerticalProductList.vue";
import Pagination from "@/Pages/Shared/Pagination.vue";
import Loader from "@/Pages/Shared/Loader.vue";
import { Inertia } from "@inertiajs/inertia";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

var ev;
export default {
    components: {
        Loader,
        Pagination,
        Link,
        Head,
        CategoryBox,
        ProductBox,
        VerticalProductBox,
        VerticalProductList,
        Splide,
        SplideSlide,
        SplideTrack,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },
    props: {
        discounted: Object,
        category: Object,
        brands: Object,
        filters: Object,
        categories: Object,
        pageType: String,
        paginateType: String,
        auth: Object,
        mobilefilter: false,
        mostRentedProducts: Object,
        canonical: String,
        showCategoryNote: Boolean,
        newTypeSeoDefinition: {
            type: Boolean,
            default: false
        },
        title: String,
        meta_description: String,
        meta_keywords: String
    },
    data() {
        return {
            selectedGridType: "category-box",
            selectedFilters: {
                brand: this.filters.brand?.split(",") ?? [],
                price: this.filters.price?.split(",") ?? [],
                category: this.filters.collections?.split(",") ?? [],
                color: [],
                storage: [],
                os: []
            },
            categoryItems: this.category,
            hiddenSeoText: true
        };
    },
    methods: {
        addToFilter(type, value) {
            // check if value is already in array
            if (this.selectedFilters[type].find((e) => e == value)) {
                // remove value from array
                this.selectedFilters[type] = this.selectedFilters[type].filter((e) => e != value);
            } else {
                // add value to array
                this.selectedFilters[type].push(value);
            }

            this.$inertia.get(`/kategoriler/${this.category.items.slug ?? "tum-urunler"}`, {
                "filter[brand]": this.selectedFilters.brand.join(","), // join array to string
                "filter[price]": this.selectedFilters.price.join(","), // join array to string,
                "filter[collections]": this.selectedFilters.category.join(",") // join array to string,
            });
        },
        splidedArray(arr, size) {
            return arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
        },
        orderCategories() {
            console.log("orderCategories");
            //console.log(this.categories);
            //return this.categories;
            window.cats = this.categories;
            return Object.values(this.categories).sort((a, b) => {
                return a.name?.name?.tr.localeCompare(b.name?.name?.tr);
            });
        }
    },
    computed: {
        splidedDiscounted() {
            // Take first 8 items than split them into 2
            return this.splidedArray(this.discounted.items.data.slice(0, 16), 1);
        }
    },
    created() {
        console.log("CategoryGrid created");

        Inertia.reload({
            only: ["discounted"]
        });

        ev = this.$inertia.on("success", (event) => {
            //console.log("Kategpri", event.detail.page.url);

            let datas = this.categoryItems.items.data;
            if (this.pageType === "category") {
                datas = this.categoryItems.items.element.products.data;
            }

            let categoryProducts = [];
            datas.map((item, keys) => {
                let product = {};

                let subscribetionMonthsOrdered = [];
                if (item.variants[0]?.prices.length > 0) subscribetionMonthsOrdered = Object.entries(item.variants[0]?.prices).sort(([, a], [, b]) => a.subscription_months.value - b.subscription_months.value);
                let nonZeroPrices = item.variants[0]?.prices.filter((price) => price.price.value > 0);
                subscribetionMonthsOrdered = subscribetionMonthsOrdered.filter((price) => price[1].price.value > 0);
                let productPrice = subscribetionMonthsOrdered[+Object.keys(nonZeroPrices).length - 1]?.[1]?.price?.value / 100;

                product.item_id = item.id;
                product.item_name = item.attribute_data.name.tr;
                product.price = productPrice;
                product.item_brand = item.brand.name;
                product.item_category = item.collections[0]?.attribute_data?.name?.tr;
                product.item_category2 = item.collections[1]?.attribute_data?.name?.tr ?? "";
                // product.item_comments = item.reviews.total;
                // product.item_rating = item.id;
                //product.item_color = item.variants[0]?.options[0] ?? ""; // Kategori verisidne yok
                product.item_list_id = "HOME-1";
                product.item_list_name = "Homepage - E Mobilite List";
                product.index = keys;
                categoryProducts.push(product);
            });

            if (event.detail.page.url.startsWith("/kategoriler")) {
                //console.log(this.category);
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        item_list_id: "HOME-" + this.category.items.element?.id,
                        item_list_name: "Category - " + this.category.items.element?.attribute_data?.name?.tr + " List",
                        items: categoryProducts
                    }
                });
            }
        });
    },
    layout: Layout,
    mounted() {
        console.log("CategoryGrid mounted");
    },
    setup() {
        const options = {
            rewind: true,
            gap: "1rem",
            perPage: 1,
            arrows: false
        };

        return { options };
    }
};
</script>

<template>
    <templete v-if="!newTypeSeoDefinition">
        <Head :title="category?.items?.element?.title">
            <meta name="description" :content="category?.items?.element?.meta_description" />
            <link rel="canonical" :href="canonical" />
        </Head>
    </templete>
    <templete v-else>
        <Head :title="title">
            <meta name="description" :content="meta_description" />
            <link rel="canonical" :href="canonical" />
        </Head>
    </templete>

    <main class="my-3 mx-auto max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl mt-4 mx-auto">
        <div class="promotion-bar w-full px-5 py-1 bg-[#FCF3EC] flex justify-center rounded-lg border-1 border-[#FCF3EC] mb-3" v-if="true">
            <div class="font-bold text-base">İlk siparişine özel tüm ürünlerde geçerli 3 ay ve üzeri kiralamalarına ek 1 aylık kullanım Kiralabunu'dan hediye!</div>
        </div>
        <section class="mb-6 flex flex-row">
            <div class="w-full lg:pt-2">
                <div class="sliderclass">
                    <splide :options="{ rewind: true, gap: '1rem', perPage: 1, arrows: false }" class="block mts:hidden ts:hidden mt-4">
                        <splide-slide>
                            <img class="w-full" src="../../images/category-banner/mobilite-banner-mobile.png" alt="" />
                        </splide-slide>

                    </splide>

                    <splide :options="options" class="hidden mts:block ts:hidden">
                        <splide-slide>
                            <img class="w-full" src="../../images/category-banner/mobilite-banner.png" alt="" />
                        </splide-slide>

                    </splide>

                    <splide :options="options" class="hidden mts:hidden ts:block">
                        <splide-slide>
                            <img class="w-full" src="../../images/category-banner/mobilite-banner.png" alt="" />
                        </splide-slide>
                    </splide>
                </div>
            </div>
        </section>
        <section class="pb-3 overflow-x-scroll">
            <div class="w-full flex space-x-8 relative w-[1400px]">
                <div class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-scooter">Elektrikli Scooter</a></div>
                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-kaykay"> Elektrikli Kaykay</a></button>
                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-bisiklet"> Elektrikli Bisiklet</a></button>
                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-araba"> Elektrikli Araba</a></button>
                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-motor"> Elektrikli Motor</a></button>
                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/sarj-istasyonlari"> Şarj İstasyonları</a></button>
                <!--                <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-robot"> Elektrikli Robot</a></button>-->
            </div>
        </section>
        <div class="mt-6 md:mt-4 py-1 md:py-9 bg-[#f8f8f8] w-full">
            <div class="flex w-full justify-between">
                <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto">Dikkat Çeken Kategoriler</div>
            </div>
            <div class="flex flex-col max-h-p-box space-x-1 pl-0 lg:pl-0 lg:p-0 lg:mt-9">
                <Splide :has-track="false" aria-label="" :options="{ rewind: true, perPage: 3, pagination: false, breakpoints: { 640: { perPage: 1 }, 1100: { perPage: 2 }, 1270: { perPage: 3 } } }">
                    <SplideTrack>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/elektrikli-arac.webp" type="image/webp" />
                                    <img src="../../images/category-banner/elektrikli-arac.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full"><a href="/kategoriler/elektrikli-araba"> Elektrikli Araçlar</a></div>
                                <div class="flex justify-center">
                                    <div class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] flex justify-center"><a class="leading-tight font-santralextrabold whitespace-nowrap" href="/kategoriler/elektrikli-araba">Ürünlere Git</a></div>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/elektrikli-motosiklet.webp" type="image/webp" />
                                    <img src="../../images/category-banner/elektrikli-motosiklet.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full"><a href="/kategoriler/elektrikli-motor">Elektrikli Motorlar</a></div>
                                <div class="flex justify-center">
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] flex justify-center"><a class="leading-tight font-santralextrabold whitespace-nowrap" href="/kategoriler/elektrikli-motor">Ürünlere Git</a></button>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/wallbox.webp" type="image/webp" />
                                    <img src="../../images/category-banner/wallbox.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full"><a class="leading-tight font-santralextrabold whitespace-nowrap" href="/kategoriler/sarj-istasyonlari">Duvar Tipi Sarj İstasyonları</a></div>
                                <div class="flex justify-center">
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%]"><a class="leading-tight font-santralextrabold whitespace-nowrap" href="/kategoriler/sarj-istasyonlari">Ürünlere Git</a></button>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/elektrikli-arac.webp" type="image/webp" />
                                    <img src="../../images/category-banner/elektrikli-arac.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full">Elektrikli Araçlar</div>
                                <div class="flex justify-center">
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold">Ürünlere Git</button>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/elektrikli-motosiklet.webp" type="image/webp" />
                                    <img src="../../images/category-banner/elektrikli-motosiklet.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full">Elektrikli Motorlar</div>
                                <div class="flex justify-center">
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold">Ürünlere Git</button>
                                </div>
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div class="px-5 my-2 w-full">
                                <picture>
                                    <source srcset="../../images/category-banner/wallbox.webp" type="image/webp" />
                                    <img src="../../images/category-banner/wallbox.png" alt="" loading="lazy" />
                                </picture>
                                <div class="text-lg md:text-xl my-4 text-center w-full">Duvar Tipi Sarj İstasyonları</div>
                                <div class="flex justify-center">
                                    <button class="bg-kbgreen text-white text-sm lg:text-base py-2 w-[200px] rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold">Ürünlere Git</button>
                                </div>
                            </div>
                        </SplideSlide>
                    </SplideTrack>
                    <div class="splide__arrows">
                        <button class="splide__arrow splide__arrow--prev !-left-9">
                            <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                            </svg>
                        </button>
                        <button class="splide__arrow splide__arrow--next !-right-9">
                            <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                            </svg>
                        </button>
                    </div>
                </Splide>

            </div>
        </div>
        <div class="flex flex-wrap mx-auto justify-center items-center relative z-40 w-full mt-12">
            <div class="w-full mts:w-6/12 ">
                <picture>
                    <source srcset="../../images/category-banner/teslamodelyson.webp" type="image/webp" />
                    <img src="../../images/category-banner/teslamodelyson.png" alt="" />
                </picture>
            </div>
            <div class="w-full mts:w-6/12 mts:pl-6">
                <div class="my-4 text-4xl font-santralregular relative z-40">Kiralabunu ile Tesla Model Y
                    serisini deneyimleyin!
                </div>
                <div class="font-santralregular text-base text-black">
                    Sürdülebilir ve teknoloji harikası olan Tesla
                    Model Y ile istediğiniz yere gidin. Tek şarjla
                    310 mile (EPA tahmini) varan menzil
                    sayesinde Model Y'den önce sizin biraz mola
                    vermeniz gerekebilir. Çift motorlu dört
                    tekerlekten çekiş gücü, her türlü yol
                    koşulunun üstesinden gelmeye hazır olmanızı
                    sağlar.
                </div>
                <div class="my-4">
                    <div class="bg-black max-w-[200px] text-white text-sm lg:text-base py-2 px-6 rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold flex justify-center"><a href="/kategoriler/elektrikli-araba">Ürünlere Git</a></div>
                </div>
            </div>
        </div>
        <section class="mt-6 md:mt-4 py-1 md:py-9 bg-[#f8f8f8]">
            <div class="flex mx-auto flex-col max-w-kbmobile md:max-w-tablet ts:max-w-ts 2xl:max-w-7xl">
                <div class="flex w-full justify-between">
                    <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto">İndirimli Ürünler</div>
                    <div class="flex-1 ml-6 hidden md:flex">
                        <div class="flex-1 self-center border border-gray-200"></div>
                        <Link href="/indirimli-urunler" class="cursor-pointer text-sm font-santralextrabold flex justify-center items-center border-2 rounded-full px-4 border-2 whitespace-nowrap md:ml-4 bg-white hover:bg-kbgreen hover:text-white"> Tümünü Gör</Link>
                    </div>
                </div>
                <div class="flex flex-col max-h-p-box space-x-1 pl-0 lg:pl-0 lg:p-0 lg:mt-9">
                    <Splide :has-track="false" aria-label="" :options="{ rewind: true, perPage: 4, pagination: false, breakpoints: { 640: { perPage: 1 }, 1100: { perPage: 2 }, 1270: { perPage: 3 } } }" v-if="discounted">
                        <SplideTrack>
                            <SplideSlide v-for="(productGroup, index) in splidedDiscounted" :key="index" class="flex">
                                <category-box v-for="(product, index) in productGroup" :product="product" :new-container-classes="`w-full`" :auth="auth" />
                            </SplideSlide>
                        </SplideTrack>
                        <div class="splide__arrows">
                            <button class="splide__arrow splide__arrow--prev !-left-9">
                                <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                    <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                                </svg>
                            </button>
                            <button class="splide__arrow splide__arrow--next !-right-9">
                                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737" viewBox="0 0 11.926 20.737">
                                    <path id="Path_18" data-name="Path 18" d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z" transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                                </svg>
                            </button>
                        </div>
                    </Splide>
                    <div v-else>
                        <loader :active="true" message="Please wait 5 seconds" />
                    </div>
                </div>
                <div class="flex-1 ml-2 flex md:hidden justify-center">
                    <Link href="/indirimli-urunler" class="cursor-pointer border-3 rounded-full py-1.5 px-2 text-center mb-4 w-32 bg-white hover:bg-kbgreen hover:text-white font-santralextrabold text-sm"> Tümünü Gör</Link>
                </div>
            </div>
        </section>
        <section class="flex flex-wrap mx-auto justify-center items-center relative z-40 w-full mt-12">
            <div class="w-full mts:w-4/12">
                <Link :href="'/kategoriler/elektrikli-motor'">
                    <picture>
                        <source srcset="../../images/category-banner/honda.webp" type="image/webp" />
                        <img class="w-full mts:pr-4 mt-4" src="../../images/category-banner/honda.png" alt="" />
                    </picture>
                </Link>
            </div>
            <div class="w-full mts:w-4/12">
                <Link :href="'/kategoriler/elektrikli-motor'">
                    <picture>
                        <source srcset="../../images/category-banner/slience.webp" type="image/webp" />
                        <img class="w-full mts:pr-4 mt-4" src="../../images/category-banner/slience.png" alt="" />
                    </picture>
                </Link>
            </div>
            <div class="w-full mts:w-4/12">
                <Link :href="'/kategoriler/elektrikli-motor'">
                    <picture>
                        <source srcset="../../images/category-banner/vespa.webp" type="image/webp" />
                        <img class="w-full mts:pl-4 mt-4" src="../../images/category-banner/vespa.png" alt="" />
                    </picture>
                </Link>
            </div>
        </section>

        <section class="mt-6 md:mt-4 py-1 md:py-9 bg-[#f8f8f8]">
            <div class="text-2xl md:text-3xl my-2 lg:my-0 mx-0 lg:mx-4 self-center text-center w-full md:w-auto">Markalar</div>
            <div class="w-full flex flex-wrap mt-8 relative lg:max-w-none o">
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/vespa">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/1.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/piaggio">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/2.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/silence">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/3.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/kymco">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/4.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/honda">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/5.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/xev">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/6.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/mg">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/7.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/byd">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/8.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/tesla">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/9.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/citroen">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/10.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/jeep">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/11.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-32" src="../../images/category-banner/brands/12.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/13.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/14.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/15.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/16.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="#">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/17.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/xiaomi">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/18.png" alt="" loading="lazy" />
                    </Link>
                </div>
                <div class="w-1/2 mts:w-1/3 lg:w-1/6 flex justify-center items-center h-24">
                    <Link href="/marka/rks">
                        <img class="grayscale hover:grayscale-0 transition-all ease-in-out duration-300 max-h-28" src="../../images/category-banner/brands/19.png" alt="" loading="lazy" />
                    </Link>
                </div>
            </div>
        </section>

        <section class="flex flex-wrap mx-auto justify-center items-center relative z-40 w-full mt-12">
            <div class="w-full mts:w-6/12 ">
                <picture>
                    <source srcset="../../images/category-banner/piaggio.webp" type="image/webp" />
                    <img src="../../images/category-banner/piaggio.png" alt="" />
                </picture>
            </div>
            <div class="w-full mts:w-6/12 mts:pl-6">
                <div class="my-4 text-4xl font-santralregular relative z-40">
                    Elektrikli Mobilitenin Yeni Adı: <br>
                    Piaggio One Serisi
                </div>
                <div class="font-santralregular text-base text-black">
                    Şehir içi seyehat hiç bu kadar kolay
                    olmamıştı. Sürmeye başladığınız andan
                    itibaren fark edeceğiniz kullanıcı dostu bir
                    deneyim sunar. Anahtarsız çalıştırma
                    mekanizması, tek düğme ile ECO ve Sport
                    modları arasında kolayca geçiş gibi farklı
                    özellikleri ile Piaggio'yu Kiralabunu farklı ile
                    deneyimle!
                </div>
                <div class="my-4">
                    <button class="bg-black max-w-[200px] text-white text-sm lg:text-base py-2 px-6 rounded-full mt-2 hover:opacity-[90%] whitespace-nowrap leading-tight font-santralextrabold"><a href="/kategoriler/elektrikli-motor">Ürünlere Git</a></button>
                </div>
            </div>
        </section>
    </main>

</template>
